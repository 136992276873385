<template>

  <div class="page-tabbar" id="app">
    <mt-header title="Genius Loci 2019" fixed></mt-header>


    <mt-popup v-if="!isMobile()" v-model="popupVisible" popup-transition="popup-fade" closeOnClickModal="false">
      Rating only on mobile devices!
    </mt-popup>


    <div class="page-wrap">

      <mt-tab-container class="page-tabbar-container" v-model="selectedTab" swipeable>
        <mt-tab-container-item id="tab1">
          <div class="card">
            <img src="./assets/dnt.jpg" alt="Avatar" style="width:100%">

            <div class="container">
              <h2>REMAKE³</h2>
              <h3>DEUTSCHES NATIONALTHEATER</h3>
              <div class="center" v-if="isMobile()">
                <rate :length="5" v-model="ratingDNT" @after-rate="submitRating" />
              </div>
              <button class="btn btn-outline-primary mb-3" v-if="isMobile()" @click="triggerProgress">Vote</button>

            </div>
          </div>
        </mt-tab-container-item>


        <mt-tab-container-item id="tab2">
          <div class="card">
            <img src="./assets/bauhaus.png" alt="Avatar" style="width:100%">
            <div class="container">
              <h2>The Shape of Sound</h2>
              <h3>NEUES BAUHAUS-MUSEUM</h3>
              <div class="center" v-if="isMobile()">
                  <rate :length="5" v-model="ratingBauhaus" @after-rate="submitRating" />
              </div>
              <button class="btn btn-outline-primary mb-3" v-if="isMobile()" @click="triggerProgress">Vote</button>

            </div>
          </div>
        </mt-tab-container-item>


        <mt-tab-container-item id="tab3">
          <div class="card">
            <img src="./assets/monami.jpg" alt="Avatar" style="width:100%">
            <div class="container">
              <h2>Reverse Imagination</h2>
              <h3>MON AMI</h3>
              <div class="center" v-if="isMobile()">
                <rate :length="5" v-model="ratingMonAmi" @after-rate="submitRating" />
              </div>
              <button class="btn btn-outline-primary mb-3" v-if="isMobile()" @click="triggerProgress">Vote</button>
            </div>
          </div>
        </mt-tab-container-item>

      </mt-tab-container>
    </div>


    <mt-tabbar v-model="selectedTab" fixed>
      <mt-tab-item id="tab1">
        <img slot="icon" src="./assets/dnt.jpg">
        DNT
      </mt-tab-item>
      <mt-tab-item id="tab2">
        <img slot="icon" src="./assets/bauhaus.png">
        Bauhausmuseum
      </mt-tab-item>
      <mt-tab-item id="tab3">
        <img slot="icon" src="./assets/monami.jpg">
        Mon Ami
      </mt-tab-item>
    </mt-tabbar>
  </div>
</template>

<script>
  import StarRating from 'vue-star-rating';
  import {
    uuid
  } from 'vue-uuid'; // uuid object is also exported to things
  import { Indicator } from 'mint-ui';

  export default {
    name: 'app',
    data() {
      return {
        deviceId: '',
        selectedTab: 'tab1',
        ratingBauhaus: '0',
        ratingMonAmi: '0',
        ratingDNT: '0',
        popupVisible: true
      }
    },
    methods: {
      isMobile: function () {
        var check = false;
        (function (a) {
          if (
            /(android|bb\d+|meego).+mobile|avantgo|bada\/|blackberry|blazer|compal|elaine|fennec|hiptop|iemobile|ip(hone|od)|iris|kindle|lge |maemo|midp|mmp|mobile.+firefox|netfront|opera m(ob|in)i|palm( os)?|phone|p(ixi|re)\/|plucker|pocket|psp|series(4|6)0|symbian|treo|up\.(browser|link)|vodafone|wap|windows ce|xda|xiino/i
            .test(a) ||
            /1207|6310|6590|3gso|4thp|50[1-6]i|770s|802s|a wa|abac|ac(er|oo|s\-)|ai(ko|rn)|al(av|ca|co)|amoi|an(ex|ny|yw)|aptu|ar(ch|go)|as(te|us)|attw|au(di|\-m|r |s )|avan|be(ck|ll|nq)|bi(lb|rd)|bl(ac|az)|br(e|v)w|bumb|bw\-(n|u)|c55\/|capi|ccwa|cdm\-|cell|chtm|cldc|cmd\-|co(mp|nd)|craw|da(it|ll|ng)|dbte|dc\-s|devi|dica|dmob|do(c|p)o|ds(12|\-d)|el(49|ai)|em(l2|ul)|er(ic|k0)|esl8|ez([4-7]0|os|wa|ze)|fetc|fly(\-|_)|g1 u|g560|gene|gf\-5|g\-mo|go(\.w|od)|gr(ad|un)|haie|hcit|hd\-(m|p|t)|hei\-|hi(pt|ta)|hp( i|ip)|hs\-c|ht(c(\-| |_|a|g|p|s|t)|tp)|hu(aw|tc)|i\-(20|go|ma)|i230|iac( |\-|\/)|ibro|idea|ig01|ikom|im1k|inno|ipaq|iris|ja(t|v)a|jbro|jemu|jigs|kddi|keji|kgt( |\/)|klon|kpt |kwc\-|kyo(c|k)|le(no|xi)|lg( g|\/(k|l|u)|50|54|\-[a-w])|libw|lynx|m1\-w|m3ga|m50\/|ma(te|ui|xo)|mc(01|21|ca)|m\-cr|me(rc|ri)|mi(o8|oa|ts)|mmef|mo(01|02|bi|de|do|t(\-| |o|v)|zz)|mt(50|p1|v )|mwbp|mywa|n10[0-2]|n20[2-3]|n30(0|2)|n50(0|2|5)|n7(0(0|1)|10)|ne((c|m)\-|on|tf|wf|wg|wt)|nok(6|i)|nzph|o2im|op(ti|wv)|oran|owg1|p800|pan(a|d|t)|pdxg|pg(13|\-([1-8]|c))|phil|pire|pl(ay|uc)|pn\-2|po(ck|rt|se)|prox|psio|pt\-g|qa\-a|qc(07|12|21|32|60|\-[2-7]|i\-)|qtek|r380|r600|raks|rim9|ro(ve|zo)|s55\/|sa(ge|ma|mm|ms|ny|va)|sc(01|h\-|oo|p\-)|sdk\/|se(c(\-|0|1)|47|mc|nd|ri)|sgh\-|shar|sie(\-|m)|sk\-0|sl(45|id)|sm(al|ar|b3|it|t5)|so(ft|ny)|sp(01|h\-|v\-|v )|sy(01|mb)|t2(18|50)|t6(00|10|18)|ta(gt|lk)|tcl\-|tdg\-|tel(i|m)|tim\-|t\-mo|to(pl|sh)|ts(70|m\-|m3|m5)|tx\-9|up(\.b|g1|si)|utst|v400|v750|veri|vi(rg|te)|vk(40|5[0-3]|\-v)|vm40|voda|vulc|vx(52|53|60|61|70|80|81|83|85|98)|w3c(\-| )|webc|whit|wi(g |nc|nw)|wmlb|wonu|x700|yas\-|your|zeto|zte\-/i
            .test(a.substr(0, 4))) check = true;
        })(navigator.userAgent || navigator.vendor || window.opera);
        return check;
      },
      submitRating(){
         this.$http.post(
             'https://competition.genius-loci-weimar.org/onsite/vote',
                 {
                   'device': this.deviceId,
                   'ratings': {
                     '5eb8a99c-9ada-44bc-bc9c-589e95b8fbf1': this.ratingBauhaus,
                     '31ab5ba5-ca8e-44b8-8966-85006462a6bc': this.ratingMonAmi,
                     'c4540823-c191-449f-9c41-35457cb8acc0': this.ratingDNT,
                   }

                 }
         ).then(
             response => {
                 console.log(response)
             }, error => {
                 console.log(error)
             }
         )
      },
      triggerProgress(){

        Indicator.open({
          spinnerType: 'double-bounce'
        });
        setTimeout(function(){
          Indicator.close();

        },parseInt(Math.floor(Math.random() * 5000) + 100))
      }
    },
    created() {
      this.deviceId = this.$cookies.get("device");


      if (this.deviceId == null) {
        this.deviceId = this.$uuid.v4();
        this.$cookies.set("device", this.deviceId, "24d");
      } else {
        this.ratingBauhaus = this.$cookies.get("ratingBauhaus");
        this.ratingMonAmi = this.$cookies.get("ratingMonAmi");
        this.ratingDNT = this.$cookies.get("ratingDNT");
      }
    },
    watch: {
      ratingBauhaus(value) {
        this.$cookies.set("ratingBauhaus", value, "24d");
      },
      ratingMonAmi(value) {
        this.$cookies.set("ratingMonAmi", value, "24d");
      },
      ratingDNT(value) {
        this.$cookies.set("ratingDNT", value, "24d");
      }
    },
    components: {
      StarRating,
    }
  }

</script>

<style lang="scss">
  #app {
    font-family: 'Avenir', Helvetica, Arial, sans-serif;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
    text-align: center;
    color: #2c3e50;
    margin-top: 60px;
    max-width: 740px;
    margin-left: auto;
    margin-right: auto;
  }

  .center {
    padding-bottom: 10px;
    display: -webkit-box;
    display: -moz-box;
    display: -ms-flexbox;
    display: -webkit-flex;
    display: flex;
    align-items: center;
    justify-content: center;
  }

  .card {
    /* Add shadows to create the "card" effect */
    box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.2);
    transition: 0.3s;
    border-radius: 5px;
    margin-bottom: 150px;
    margin: 20px;
    /* 5px rounded corners */

  }

  /* On mouse-over, add a deeper shadow */
  .card:hover {
    box-shadow: 0 8px 16px 0 rgba(0, 0, 0, 0.2);
  }

  /* Add some padding inside the card container */
  .container {
    padding: 2px 16px;
  }

  /* Add rounded corners to the top left and the top right corner of the image */
  img {
    border-radius: 5px 5px 0 0;
  }

  .page-tabbar {
    overflow: hidden;
    height: 100vh;
  }

  .page-wrap {
    overflow: auto;
    height: 100%;
    padding-bottom: 100px;
  }

</style>
