var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "page-tabbar", attrs: { id: "app" } },
    [
      _c("mt-header", { attrs: { title: "Genius Loci 2019", fixed: "" } }),
      _vm._v(" "),
      !_vm.isMobile()
        ? _c(
            "mt-popup",
            {
              attrs: {
                "popup-transition": "popup-fade",
                closeOnClickModal: "false"
              },
              model: {
                value: _vm.popupVisible,
                callback: function($$v) {
                  _vm.popupVisible = $$v
                },
                expression: "popupVisible"
              }
            },
            [_vm._v("\n    Rating only on mobile devices!\n  ")]
          )
        : _vm._e(),
      _vm._v(" "),
      _c(
        "div",
        { staticClass: "page-wrap" },
        [
          _c(
            "mt-tab-container",
            {
              staticClass: "page-tabbar-container",
              attrs: { swipeable: "" },
              model: {
                value: _vm.selectedTab,
                callback: function($$v) {
                  _vm.selectedTab = $$v
                },
                expression: "selectedTab"
              }
            },
            [
              _c("mt-tab-container-item", { attrs: { id: "tab1" } }, [
                _c("div", { staticClass: "card" }, [
                  _c("img", {
                    staticStyle: { width: "100%" },
                    attrs: { src: require("./assets/dnt.jpg"), alt: "Avatar" }
                  }),
                  _vm._v(" "),
                  _c("div", { staticClass: "container" }, [
                    _c("h2", [_vm._v("REMAKE³")]),
                    _vm._v(" "),
                    _c("h3", [_vm._v("DEUTSCHES NATIONALTHEATER")]),
                    _vm._v(" "),
                    _vm.isMobile()
                      ? _c(
                          "div",
                          { staticClass: "center" },
                          [
                            _c("rate", {
                              attrs: { length: 5 },
                              on: { "after-rate": _vm.submitRating },
                              model: {
                                value: _vm.ratingDNT,
                                callback: function($$v) {
                                  _vm.ratingDNT = $$v
                                },
                                expression: "ratingDNT"
                              }
                            })
                          ],
                          1
                        )
                      : _vm._e(),
                    _vm._v(" "),
                    _vm.isMobile()
                      ? _c(
                          "button",
                          {
                            staticClass: "btn btn-outline-primary mb-3",
                            on: { click: _vm.triggerProgress }
                          },
                          [_vm._v("Vote")]
                        )
                      : _vm._e()
                  ])
                ])
              ]),
              _vm._v(" "),
              _c("mt-tab-container-item", { attrs: { id: "tab2" } }, [
                _c("div", { staticClass: "card" }, [
                  _c("img", {
                    staticStyle: { width: "100%" },
                    attrs: {
                      src: require("./assets/bauhaus.png"),
                      alt: "Avatar"
                    }
                  }),
                  _vm._v(" "),
                  _c("div", { staticClass: "container" }, [
                    _c("h2", [_vm._v("The Shape of Sound")]),
                    _vm._v(" "),
                    _c("h3", [_vm._v("NEUES BAUHAUS-MUSEUM")]),
                    _vm._v(" "),
                    _vm.isMobile()
                      ? _c(
                          "div",
                          { staticClass: "center" },
                          [
                            _c("rate", {
                              attrs: { length: 5 },
                              on: { "after-rate": _vm.submitRating },
                              model: {
                                value: _vm.ratingBauhaus,
                                callback: function($$v) {
                                  _vm.ratingBauhaus = $$v
                                },
                                expression: "ratingBauhaus"
                              }
                            })
                          ],
                          1
                        )
                      : _vm._e(),
                    _vm._v(" "),
                    _vm.isMobile()
                      ? _c(
                          "button",
                          {
                            staticClass: "btn btn-outline-primary mb-3",
                            on: { click: _vm.triggerProgress }
                          },
                          [_vm._v("Vote")]
                        )
                      : _vm._e()
                  ])
                ])
              ]),
              _vm._v(" "),
              _c("mt-tab-container-item", { attrs: { id: "tab3" } }, [
                _c("div", { staticClass: "card" }, [
                  _c("img", {
                    staticStyle: { width: "100%" },
                    attrs: {
                      src: require("./assets/monami.jpg"),
                      alt: "Avatar"
                    }
                  }),
                  _vm._v(" "),
                  _c("div", { staticClass: "container" }, [
                    _c("h2", [_vm._v("Reverse Imagination")]),
                    _vm._v(" "),
                    _c("h3", [_vm._v("MON AMI")]),
                    _vm._v(" "),
                    _vm.isMobile()
                      ? _c(
                          "div",
                          { staticClass: "center" },
                          [
                            _c("rate", {
                              attrs: { length: 5 },
                              on: { "after-rate": _vm.submitRating },
                              model: {
                                value: _vm.ratingMonAmi,
                                callback: function($$v) {
                                  _vm.ratingMonAmi = $$v
                                },
                                expression: "ratingMonAmi"
                              }
                            })
                          ],
                          1
                        )
                      : _vm._e(),
                    _vm._v(" "),
                    _vm.isMobile()
                      ? _c(
                          "button",
                          {
                            staticClass: "btn btn-outline-primary mb-3",
                            on: { click: _vm.triggerProgress }
                          },
                          [_vm._v("Vote")]
                        )
                      : _vm._e()
                  ])
                ])
              ])
            ],
            1
          )
        ],
        1
      ),
      _vm._v(" "),
      _c(
        "mt-tabbar",
        {
          attrs: { fixed: "" },
          model: {
            value: _vm.selectedTab,
            callback: function($$v) {
              _vm.selectedTab = $$v
            },
            expression: "selectedTab"
          }
        },
        [
          _c("mt-tab-item", { attrs: { id: "tab1" } }, [
            _c("img", {
              attrs: { slot: "icon", src: require("./assets/dnt.jpg") },
              slot: "icon"
            }),
            _vm._v("\n      DNT\n    ")
          ]),
          _vm._v(" "),
          _c("mt-tab-item", { attrs: { id: "tab2" } }, [
            _c("img", {
              attrs: { slot: "icon", src: require("./assets/bauhaus.png") },
              slot: "icon"
            }),
            _vm._v("\n      Bauhausmuseum\n    ")
          ]),
          _vm._v(" "),
          _c("mt-tab-item", { attrs: { id: "tab3" } }, [
            _c("img", {
              attrs: { slot: "icon", src: require("./assets/monami.jpg") },
              slot: "icon"
            }),
            _vm._v("\n      Mon Ami\n    ")
          ])
        ],
        1
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }