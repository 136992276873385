
require('../css/app.css');
//require('../css/global.scss');
require('../../node_modules/bootstrap/dist/css/bootstrap.css');

require('../../node_modules/bootstrap/dist/js/bootstrap.js');

const $ = require('jquery');
global.$ = global.jQuery = $;
// this "modifies" the jquery module: adding behavior to it
// the bootstrap module doesn't export/return anything
require('bootstrap');

require('../../node_modules/font-awesome/css/font-awesome.min.css');

// or you can include specific pieces
// require('bootstrap/js/dist/tooltip');
// require('bootstrap/js/dist/popover');

document.addEventListener('DOMContentLoaded', function(){
    //document.domain = location.hostname.split('.').slice(1).join('.');
    $('[data-toggle="popover"]').popover();

    $('#project_crewPdfFile_file').on('change',function(){
        //get the file name
        var fileName = $(this).val();
        //replace the "Choose a file" label
        $(this).next('.custom-file-label').html(fileName);
    });

    $('#project_projectPdfFile_file').on('change',function(){
        //get the file name
        var fileName = $(this).val();
        //replace the "Choose a file" label
        $(this).next('.custom-file-label').html(fileName);
    });

    $('#project_previewImageFile_file').on('change',function(){
        //get the file name
        var fileName = $(this).val();
        //replace the "Choose a file" label
        $(this).next('.custom-file-label').html(fileName);
    });
});



// ./assets/js/index.js
import Vue from 'vue';

import UUID from 'vue-uuid';
import Mint from 'mint-ui';
import 'mint-ui/lib/style.css'
import App from './App.vue'
import rate from 'vue-rate';
import VueResource from 'vue-resource';

Vue.use(rate);

Vue.use(Mint);
Vue.use(UUID);
Vue.use(require('vue-cookies'));

Vue.use(VueResource);

if(document.getElementById('#vueApp')){
    new Vue({
        el: '#vueApp',
        render: h => h(App)
    });
}

